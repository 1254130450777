import type languageType from "./type.js";
const hk: languageType = {
  navbar: {
    fontPage: "首頁",
    gameList: "選擇遊戲一鍵開服",
    helpCenter: "幫助中心",
    newsCenter: "新聞中心",
    login: "登錄",
    register: "註冊",
    aboutUs: "關於我們",
    searchGame: "搜索遊戲",
    userCenter: "用戶中心",
  },
  footer: {
    companyName: "粒子數據（深圳）有限公司",
    company: "公司",
    aboutUs: "關於我們",
    contactUs: "聯繫我們",
    joinUs: "加入我們",
    game: "遊戲",
    topGames: "熱門遊戲",
    newGamesRecomand: "新遊推薦",
    gameRanking: "遊戲排行",
    comunity: "社區",
    playerForum: "玩家論壇",
    gameStrategy: "遊戲攻略",
    playerShare: "玩家分享",
    focusUs: "關注我們",
    copyright: "©2024 粒子數據（深圳）有限公司。保留所有權。",
    icp: "粤ICP备2022088627号-4",
    publicNet: "粤公网安备44030902003679号",
    privacy: "隱私政策",
    termsOfService: "服務條款",
    cookieSettings: "Cookie 設置",
    companyInfo:
      "我们的核心产品包括更便捷更高效的控制面板和先进的服务器管理工具，旨在简化复杂的技术操作，使零基础用户也能轻松上手。更多服务游戏玩家产品开发中，敬请期待。",
    companyInfo1: "社群",
    companyInfo3: "地址",
    companyInfo4: "謝謝您的支持與信任！",
    companyAddress: "廣東省深圳市寶安區西鄉街道固戍社区滨海灣1號711",
    onlineServiceText: "享受24小時在線穩定服務",
    onlineServiceBtn: "點擊創建您的遊戲伺服器",
  },
  container: {
    quantumPanel: "量子面板",
    linkageDiscount: "聯動折扣",
    summerSpecial: "暑期特惠",
    agencyDiscount: "代理折扣",
    quantumGamePanel: "量子遊戲面板",
    panelDescrible: "我們提供多樣的Web遊戲伺服器服務，滿足您的遊戲需求。",
    buttonViewGame: "查看遊戲",
    buttonStartServer: "立即開服",
    minecraft: "我的世界",
    minecraftDescrible:
      "我的世界是一款沙盒遊戲，玩家可以在遊戲中進行建造、採集、戰鬥等操作。",
    palworld: "幻獸帕魯",
    palworldDescrible:
      "幻獸帕魯是一款3D動作冒險遊戲，玩家可以在遊戲中進行戰鬥、探索、收集等操作。",
    csgo: "CSGO2",
    csgoDescrible: "CSGO是一款射擊遊戲，玩家可以在遊戲中進行射擊、對抗等操作。",
    riskInTheRain: "雨中冒險",
    riskInTheRainDescrible:
      "雨中冒險是一款益智冒險遊戲，玩家可以在遊戲中進行解謎、探索等操作。",
    imperialMyth: "帝國神話",
    imperialMythDescrible:
      "帝國神話是一款策略遊戲，玩家可以在遊戲中進行策略、戰鬥等操作。",
    sevenDays: "七日殺",
    sevenDaysDescrible:
      "七日殺是一款生存遊戲，玩家可以在遊戲中進行生存、對抗等操作。",
    mistlockKingdom: "霧鎖王國",
    mistlockKingdomDescrible:
      "霧鎖王國是一款策略遊戲，玩家可以在遊戲中進行策略、戰鬥等操作。",
    forest: "森林",
    forestDescrible:
      "森林是一款策略遊戲，玩家可以在遊戲中進行策略、戰鬥等操作。",
    terraria: "泰拉瑞亞",
    terrariaDescrible:
      "泰拉瑞亞是一款沙盒遊戲，玩家可以在遊戲中進行建造、採集、戰鬥等操作。",
    starve: "饑荒",
    starveDescrible:
      "饑荒是一款生存遊戲，玩家可以在遊戲中進行生存、對抗等操作。",
    craftopia: "創世理想鄉",
    craftopiaDescrible:
      "創世理想鄉是一款益智遊戲，玩家可以在遊戲中進行解謎、探索等操作。",
  },
  banner: {
    title: "多樣的遊戲選擇",
    titleDescrible: "我們提供多種熱門遊戲的伺服器，滿足您的遊戲需求。",
    imgGameName1: "CSGO2",
    imgGameDescrible1:
      "CSGO是一款射擊遊戲，玩家可以在遊戲中進行射擊、對抗等操作。",
    imgGameName2: "我的世界",
    imgGameDescrible2:
      "我的世界是一款沙盒遊戲，玩家可以在遊戲中進行建造、採集、戰鬥等操作。",
  },
  scrollList: {
    imgGameName2: "我的世界",
    imgGameDescrible2:
      "我的世界是一款沙盒遊戲，玩家可以在遊戲中進行建造、採集、戰鬥等操作。",
  },
  testimonial: {
    title: "精彩的遊戲體驗",
    titleDescrible: "用戶對我們遊戲伺服器的評價",
    bottonViewMore: "了解更多",
    playerName1: "呆子",
    playerEvaluate1:
      "非常棒的遊戲伺服器，穩定性很高，玩家互動很有趣。強烈推薦！",
    playerName2: "會魔法的老人",
    playerEvaluate2: "遊戲伺服器很穩定，遊戲體驗很好，推薦給大家！",
    playerName3: "滿山猴子我腚最紅",
    playerEvaluate3: "我在這個遊戲伺服器上度過了很多美好的時光，真的很喜歡！",
  },
};

export default { ...hk };
