import { default as indexLU6GY3V2qyMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/aboutUs/index.vue?macro=true";
import { default as index1ZeZjG7QSTMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/coupons/index.vue?macro=true";
import { default as indexs78LqNlFQLMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/gameCustomConfig/index.vue?macro=true";
import { default as indexmof43cnAM3Meta } from "D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/gameServerDetail/index.vue?macro=true";
import { default as indexrcn1zRJJkXMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/index.vue?macro=true";
import { default as indexkSCbQpOITFMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/helperCenter/index.vue?macro=true";
import { default as indexjVQOxac9EYMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/index.vue?macro=true";
import { default as indexE21uRMXY6jMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/login/index.vue?macro=true";
import { default as indexA9ms7Wr78iMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/newsCenter/index.vue?macro=true";
import { default as index7kRKQPUQKFMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/newsCenter/newsContent/index.vue?macro=true";
import { default as indexz8aiHsvxTsMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/payPanel/index.vue?macro=true";
import { default as indexPQwSpCBKnAMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/billManage/index.vue?macro=true";
import { default as indexDkqJXjPqbCMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/contactUs/index.vue?macro=true";
import { default as indexyXJZu6wrsdMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/couponSquare/index.vue?macro=true";
import { default as indexJHhZF9onOOMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/couponSquare/myCoupons/index.vue?macro=true";
import { default as apiexU4r5MLxXMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/financeInfo/api.ts?macro=true";
import { default as indexyMKvxnhpDJMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/financeInfo/index.vue?macro=true";
import { default as indexMGsCjADUPcMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/index.vue?macro=true";
import { default as indexRKQcaTD3qsMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/personalConfig/index.vue?macro=true";
import { default as indexbcBb5uogwWMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/serverList/index.vue?macro=true";
import { default as indexKb67tiJ17DMeta } from "D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/serverList/myServer/index.vue?macro=true";
export default [
  {
    name: "aboutUs",
    path: "/aboutUs",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/aboutUs/index.vue").then(m => m.default || m)
  },
  {
    name: "gameServerList-coupons",
    path: "/gameServerList/coupons",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "gameServerList-gameCustomConfig",
    path: "/gameServerList/gameCustomConfig",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/gameCustomConfig/index.vue").then(m => m.default || m)
  },
  {
    name: "gameServerList-gameServerDetail",
    path: "/gameServerList/gameServerDetail",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/gameServerDetail/index.vue").then(m => m.default || m)
  },
  {
    name: "gameServerList",
    path: "/gameServerList",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/gameServerList/index.vue").then(m => m.default || m)
  },
  {
    name: "helperCenter",
    path: "/helperCenter",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/helperCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexE21uRMXY6jMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "newsCenter",
    path: "/newsCenter",
    meta: indexA9ms7Wr78iMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/newsCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsCenter-newsContent",
    path: "/newsCenter/newsContent",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/newsCenter/newsContent/index.vue").then(m => m.default || m)
  },
  {
    name: "payPanel",
    path: "/payPanel",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/payPanel/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-billManage",
    path: "/userCenter/billManage",
    meta: indexPQwSpCBKnAMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/billManage/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-contactUs",
    path: "/userCenter/contactUs",
    meta: indexDkqJXjPqbCMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/contactUs/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-couponSquare",
    path: "/userCenter/couponSquare",
    meta: indexyXJZu6wrsdMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/couponSquare/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-couponSquare-myCoupons",
    path: "/userCenter/couponSquare/myCoupons",
    meta: indexJHhZF9onOOMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/couponSquare/myCoupons/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-financeInfo-api",
    path: "/userCenter/financeInfo/api",
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/financeInfo/api.ts").then(m => m.default || m)
  },
  {
    name: "userCenter-financeInfo",
    path: "/userCenter/financeInfo",
    meta: indexyMKvxnhpDJMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/financeInfo/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter",
    path: "/userCenter",
    meta: indexMGsCjADUPcMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-personalConfig",
    path: "/userCenter/personalConfig",
    meta: indexRKQcaTD3qsMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/personalConfig/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-serverList",
    path: "/userCenter/serverList",
    meta: indexbcBb5uogwWMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/serverList/index.vue").then(m => m.default || m)
  },
  {
    name: "userCenter-serverList-myServer",
    path: "/userCenter/serverList/myServer",
    meta: indexKb67tiJ17DMeta || {},
    component: () => import("D:/frontend_project/Quantum_Frontend_V2/pages/userCenter/serverList/myServer/index.vue").then(m => m.default || m)
  }
]