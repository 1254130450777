<template>
  <div class="app">
    <NuxtLoadingIndicator />
    <Loading v-if="showLoading" />
    <NuxtLayout>
      <!-- 只在客户端渲染，就不会导致 Hydration node mismatch -->
      <!-- <ClientOnly> -->
      <!-- 页面内容 -->
      <NuxtPage></NuxtPage>
      <!-- </ClientOnly> -->
    </NuxtLayout>
  </div>
</template>
<script lang="ts" setup>
import Loading from '@/components/loading/loading.vue';

const showLoading = ref(false);
const nuxtApp = useNuxtApp();

nuxtApp.hook("page:start", () => {
  showLoading.value = true;
});

nuxtApp.hook("page:finish", () => {
  // 这样配置的话所有带有网络请求的页面都会停留一秒
  // setTimeout(() => {
  //   showLoading.value = false;
  // }, 1000);
  showLoading.value = false;
});

// 引入 统计访问
// useHead({
//   script: [
//     {
//       type: 'text/javascript',
//       innerHTML: `
//         LA.init({
//           id: '3J6hfloBETnaWV8S',
//           ck: '3J6hfloBETnaWV8S',
//           autoTrack: true,
//           hashMode: true
//         });
//       `,
//       key: 'la-init-script'
//     }
//   ]
// })
</script>

<style lang="less">
.app {
  width: 100%;
  height: 100%;
  // prettier-ignore
  min-width: 1000PX;
  min-height: 100vh;
  overflow-x: auto;
  /* 字体颜色 */
  color: var(--text-color);
  /* 背景颜色 */
  background-color: var(--bg-color);

  &::-webkit-scrollbar {
    width: 10px;
    // height: 8px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道：内阴影+圆角*/
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /*定义滑块：内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 0.059rem;
    background-color: #555;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
