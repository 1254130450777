<template>
  <div class="error">

    <el-result title="页面貌似有点问题！" sub-title="生活总归带点荒谬!">
      <template #icon>
        <el-image :src="errorPic" :lazy="true" style="border-radius: 0.1rem; width: 2.4rem;" />
      </template>
      <template #extra>

        <el-button type="success" size="large" style="font-weight: 700; width: 0.8rem; height: 0.24rem;"
          @click="navigateTo('/')">回到首页</el-button>
        <h3 style="margin-top: 0.11rem;">{{ error.message }}</h3>
        <div v-html="error.stack"></div>
      </template>
    </el-result>
  </div>
</template>

<script setup lang="ts">
import errorPic from '@/assets/img/404.webp'

const error = useError()


</script>

<style lang="less" scoped>
.error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>



