(function flexible(window: Window, document: Document) {
  const docEl: HTMLElement = document.documentElement
  const dpr: number = window.devicePixelRatio || 1

  // 调整 body 的字体大小
  function setBodyFontSize(): void {
    if (document.body) {
      document.body.style.fontSize = `${12 * dpr}px`
    } else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize()

  // 设置 1rem = viewWidth / 10
  function setRemUnit(): void {
    let rem: number = docEl.clientWidth / 10
    if (rem < 100) rem = 100
    docEl.style.fontSize = `${rem}px`
  }

  setRemUnit()

  // 在页面大小调整时重置 rem 单位
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e: PageTransitionEvent) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // 检测是否支持 0.5px
  if (dpr >= 2) {
    const fakeBody: HTMLBodyElement = document.createElement('body')
    const testElement: HTMLDivElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  }
})(window, document)
