import validate from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "D:/frontend_project/Quantum_Frontend_V2/middleware/auth.global.ts";
import manifest_45route_45rule from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  redirect: () => import("D:/frontend_project/Quantum_Frontend_V2/middleware/redirect.ts")
}