import useLoginStore from "@/stores/login/login";
// 不能在内部使用 异步请求
export default defineNuxtRouteMiddleware((to, from) => {
  const loginStore = useLoginStore();
  const token = useTokenCookie();

  // 只要在首次进入页面的时候验证 token 的有效

  if (token.value) {
    // 由于无感刷新，每次必须拿到最新的 token
    loginStore.setToken(token.value);
  } else {
    // 没有值的情况可能是：关闭了会话窗口
    token.value = loginStore.getToken();
  }

  return;
});
