import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMainContainer, LazyMainGameSwiper, LazyMainOurTechnology, LazyMainScrollList, LazyMainSimperStart, LazyMainTestimonial, LazyMainDialog, LazyMainFallChildOne, LazyMainFallChildThree, LazyMainFallChildTwo, LazyMainFall, LazyMainFooter, LazyMainNavbar, LazyMainSwiperChildItem1, LazyMainSwiperChildItem2, LazyMainSwiperChildItem3, LazyMainSwiperChildItem4, LazyMainSwiper, LazyGameListBox, LazyGameListBoxList, LazyGameListCoupons, LazyGameListCustomConfigContent, LazyGameListCustomConfigFastConfig, LazyGameListCustomConfigSelectConfig, LazyGameListDetailHeader, LazyGameListGameDetail, LazyGameListServerConfigContent, LazyGameListServerConfigQuickServer, LazyGameListServerConfigSelectServer, LazyProtocolDialog, LazyPaySuccess, LazyUserCenterLeftTabs, LazyUserCenterMyServerSVGIconFile, LazyUserCenterMyServerSVGIconFolder, LazyUserCenterMyServerBackupManage, LazyUserCenterMyServerControlPanel, LazyUserCenterMyServerEchartsPie, LazyUserCenterMyServerFiles, LazyUserCenterMyServerGameConfig, LazyUserCenterMyServerOthers, LazyUserCenterMyServerVueTerminal, LazyUserCenterRightApi, LazyUserCenterRightFinance, LazyUserCenterRight, LazyUserCenterRightNotice, LazyUserCenterRightSwiper, LazyFinanceInfoCard } from '#components'
const lazyGlobalComponents = [
  ["MainContainer", LazyMainContainer],
["MainGameSwiper", LazyMainGameSwiper],
["MainOurTechnology", LazyMainOurTechnology],
["MainScrollList", LazyMainScrollList],
["MainSimperStart", LazyMainSimperStart],
["MainTestimonial", LazyMainTestimonial],
["MainDialog", LazyMainDialog],
["MainFallChildOne", LazyMainFallChildOne],
["MainFallChildThree", LazyMainFallChildThree],
["MainFallChildTwo", LazyMainFallChildTwo],
["MainFall", LazyMainFall],
["MainFooter", LazyMainFooter],
["MainNavbar", LazyMainNavbar],
["MainSwiperChildItem1", LazyMainSwiperChildItem1],
["MainSwiperChildItem2", LazyMainSwiperChildItem2],
["MainSwiperChildItem3", LazyMainSwiperChildItem3],
["MainSwiperChildItem4", LazyMainSwiperChildItem4],
["MainSwiper", LazyMainSwiper],
["GameListBox", LazyGameListBox],
["GameListBoxList", LazyGameListBoxList],
["GameListCoupons", LazyGameListCoupons],
["GameListCustomConfigContent", LazyGameListCustomConfigContent],
["GameListCustomConfigFastConfig", LazyGameListCustomConfigFastConfig],
["GameListCustomConfigSelectConfig", LazyGameListCustomConfigSelectConfig],
["GameListDetailHeader", LazyGameListDetailHeader],
["GameListGameDetail", LazyGameListGameDetail],
["GameListServerConfigContent", LazyGameListServerConfigContent],
["GameListServerConfigQuickServer", LazyGameListServerConfigQuickServer],
["GameListServerConfigSelectServer", LazyGameListServerConfigSelectServer],
["ProtocolDialog", LazyProtocolDialog],
["PaySuccess", LazyPaySuccess],
["UserCenterLeftTabs", LazyUserCenterLeftTabs],
["UserCenterMyServerSVGIconFile", LazyUserCenterMyServerSVGIconFile],
["UserCenterMyServerSVGIconFolder", LazyUserCenterMyServerSVGIconFolder],
["UserCenterMyServerBackupManage", LazyUserCenterMyServerBackupManage],
["UserCenterMyServerControlPanel", LazyUserCenterMyServerControlPanel],
["UserCenterMyServerEchartsPie", LazyUserCenterMyServerEchartsPie],
["UserCenterMyServerFiles", LazyUserCenterMyServerFiles],
["UserCenterMyServerGameConfig", LazyUserCenterMyServerGameConfig],
["UserCenterMyServerOthers", LazyUserCenterMyServerOthers],
["UserCenterMyServerVueTerminal", LazyUserCenterMyServerVueTerminal],
["UserCenterRightApi", LazyUserCenterRightApi],
["UserCenterRightFinance", LazyUserCenterRightFinance],
["UserCenterRight", LazyUserCenterRight],
["UserCenterRightNotice", LazyUserCenterRightNotice],
["UserCenterRightSwiper", LazyUserCenterRightSwiper],
["FinanceInfoCard", LazyFinanceInfoCard],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
