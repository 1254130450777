import type languageType from "./type.js";
const cn: languageType = {
  navbar: {
    fontPage: "首页",
    gameList: "选择游戏一键开服",
    helpCenter: "帮助中心",
    newsCenter: "新闻中心",
    login: "登录",
    register: "注册",
    aboutUs: "关于我们",
    searchGame: "搜索游戏",
    userCenter: "用户中心",
  },
  footer: {
    companyName: "粒子数据(深圳)有限公司",
    company: "公司",
    aboutUs: "关于我们",
    contactUs: "联系我们",
    joinUs: "加入我们",
    game: "游戏",
    topGames: "热门游戏",
    newGamesRecomand: "新游推荐",
    gameRanking: "游戏排行",
    comunity: "社区",
    playerForum: "玩家论坛",
    gameStrategy: "游戏攻略",
    playerShare: "玩家分享",
    focusUs: "关注我们",
    copyright: "©2024 粒子数据(深圳)有限公司产品版权所有。保留所有权利。",
    icp: "粤ICP备2022088627号-4",
    publicNet: "粤公网安备44030902003679号",
    privacy: "隐私政策",
    termsOfService: "服务条款",
    cookieSettings: "Cookie设置",
    companyInfo:
      "我们的核心产品包括更便捷更高效的控制面板和先进的服务器管理工具，旨在简化复杂的技术操作，使零基础用户也能轻松上手。更多服务游戏玩家产品开发中，敬请期待。",
    companyInfo1: "社群",
    companyInfo3: "地址",
    companyInfo4: "感谢您的支持与信任！",
    companyAddress: " 广东省深圳市宝安区西乡街道固戍社区滨海湾1号711",
    onlineServiceText: "享受24小时在线稳定服务",
    onlineServiceBtn: "点击创建专属的游戏服务器",
  },
  container: {
    quantumPanel: "量子面板",
    linkageDiscount: "联动折扣",
    summerSpecial: "暑期特惠",
    agencyDiscount: "代理折扣",
    quantumGamePanel: "量子游戏面板",
    panelDescrible: "我们提供多样的Web游戏服务器服务，满足您的游戏需求。",
    buttonViewGame: "查看游戏",
    buttonStartServer: "立即开服",
    minecraft: "我的世界",
    minecraftDescrible:
      "我的世界是一款沙盒游戏，玩家可以在游戏中进行建造、采集、战斗等操作。",
    palworld: "幻兽帕鲁",
    palworldDescrible:
      "幻兽帕鲁是一款3D动作冒险游戏，玩家可以在游戏中进行战斗、探索、收集等操作。",
    csgo: "CSGO2",
    csgoDescrible: "CSGO是一款射击游戏，玩家可以在游戏中进行射击、对抗等操作。",
    riskInTheRain: "雨中冒险",
    riskInTheRainDescrible:
      "雨中冒险是一款益智冒险游戏，玩家可以在游戏中进行解谜、探索等操作。",
    imperialMyth: "帝国神话",
    imperialMythDescrible:
      "帝国神话是一款策略游戏，玩家可以在游戏中进行策略、战斗等操作。",
    sevenDays: "七日杀",
    sevenDaysDescrible:
      "七日杀是一款生存游戏，玩家可以在游戏中进行生存、对抗等操作。",
    mistlockKingdom: "雾锁王国",
    mistlockKingdomDescrible:
      "雾锁王国是一款策略游戏，玩家可以在游戏中进行策略、战斗等操作。",
    forest: "森林",
    forestDescrible:
      "森林是一款策略游戏，玩家可以在游戏中进行策略、战斗等操作。",
    terraria: "泰拉瑞亚",
    terrariaDescrible:
      "泰拉瑞亚是一款沙盒游戏，玩家可以在游戏中进行建造、采集、战斗等操作。",
    starve: "饥荒",
    starveDescrible:
      "饥荒是一款生存游戏，玩家可以在游戏中进行生存、对抗等操作。",
    craftopia: "创世理想乡",
    craftopiaDescrible:
      "创世理想乡是一款策略游戏，玩家可以在游戏中进行策略、战斗等操作。",
  },
  banner: {
    title: "多样的游戏选择",
    titleDescrible: "我们提供多种热门游戏的服务器，满足您的游戏需求。",
    imgGameName1: "CSGO2",
    imgGameDescrible1:
      "CSGO是一款射击游戏，玩家可以在游戏中进行射击、对抗等操作。",
    imgGameName2: "我的世界",
    imgGameDescrible2:
      "我的世界是一款沙盒游戏，玩家可以在游戏中进行建造、采集、战斗等操作。",
  },
  scrollList: {
    imgGameName2: "我的世界",
    imgGameDescrible2:
      "我的世界是一款沙盒游戏，玩家可以在游戏中进行建造、采集、战斗等操作。",
  },
  testimonial: {
    title: "精彩的游戏体验",
    titleDescrible: "用户对我们游戏服务器的评价",
    bottonViewMore: "了解更多",
    playerName1: "呆子",
    playerEvaluate1:
      "非常棒的游戏服务器，稳定性很高，玩家互动很有趣。强烈推荐！",
    playerName2: "会魔法的老人",
    playerEvaluate2: "游戏服务器很稳定，游戏体验很好，推荐给大家！",
    playerName3: "满山猴子我腚最红",
    playerEvaluate3: "我在这个游戏服务器上度过了很多美好的时光，真的很喜欢！",
  },
};

export default { ...cn };
