import { fetchData } from "../request";

// 获取token是否有效
export function getTokenValid(token: string) {
  return fetchData.post({
    url: "api.system.CheckJWT/handle",
    opts: {
      token,
    },
  });
}

// 获取验证码
export function getVerifyCode(phoneCode, mobile) {
  return fetchData.post({
    url: `api.system.SendCode/sendPhoneCode`,
    opts: {
      phone_code: phoneCode,
      mobile: mobile,
    },
  });
}

// 注册/登入，验证码的方式
export function getUserLogin(
  phone_code,
  mobile,
  code,
  password,
  password_confirm
) {
  return fetchData.post({
    url: "client.user.UserLogin/registerByMobileAndPwd",
    opts: {
      phone_code,
      mobile,
      code,
      password,
      password_confirm,
    },
  });
}

// 验证码登入
export function getUserLoginByCode(phone_code, mobile, code) {
  return fetchData.post({
    url: "client.user.UserLogin/registerByMobileThenLogin",
    opts: {
      phone_code,
      mobile,
      code,
    },
  });
}

// 密码登入
export function getUserLoginByPwd(phone_code, mobile, password) {
  return fetchData.post({
    url: "client.user.UserLogin/login",
    opts: {
      phone_code,
      mobile,
      password,
    },
  });
}
