import revive_payload_client_jLNiy1g5Wk from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vxiL2Ga5jV from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ey1oEtWkjQ from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vphcv3kd8w from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GdMRowuZdD from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Fnbgk3wkbm from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QVcgts4vsu from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M5nXWzb29V from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.2_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/frontend_project/Quantum_Frontend_V2/.nuxt/components.plugin.mjs";
import prefetch_client_WwoPH1PE6O from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_less@4.2.0_m_hbc3xglgsagap62iygn6i73ixy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "D:/frontend_project/Quantum_Frontend_V2/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "D:/frontend_project/Quantum_Frontend_V2/.nuxt/element-plus-injection.plugin.mjs";
import plugin_zgmAQBB2FR from "D:/frontend_project/Quantum_Frontend_V2/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.2_typesc_cdj3r65cjep7b4fjd3ihcljwgm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import plugins_gZVkQZouNK from "D:/frontend_project/Quantum_Frontend_V2/plugins/plugins.ts";
import fontAwesomeIcon_T0CsKB4MdJ from "D:/frontend_project/Quantum_Frontend_V2/plugins/fontAwesomeIcon.ts";
import i18n_VfGcjrvSkj from "D:/frontend_project/Quantum_Frontend_V2/plugins/i18n.ts";
export default [
  revive_payload_client_jLNiy1g5Wk,
  unhead_vxiL2Ga5jV,
  router_ey1oEtWkjQ,
  payload_client_vphcv3kd8w,
  navigation_repaint_client_GdMRowuZdD,
  check_outdated_build_client_Fnbgk3wkbm,
  chunk_reload_client_QVcgts4vsu,
  plugin_vue3_M5nXWzb29V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WwoPH1PE6O,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_zgmAQBB2FR,
  plugins_gZVkQZouNK,
  fontAwesomeIcon_T0CsKB4MdJ,
  i18n_VfGcjrvSkj
]