import type { NitroFetchRequest } from "nitropack";
import type { FetchOptions, FetchResponse } from "ofetch";
import type { Action } from "element-plus";

interface Params {
  url: NitroFetchRequest;
  opts?: Record<string, any>;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  contentType?:
    | "application/json"
    | "application/x-www-form-urlencoded"
    | "multipart/form-data"
    | "text/plain";
  timeout?: number;
}

let reqNum: number = 0;
let alertFlag: boolean = true;
let isShowMessage: boolean = true;

// 转换动态接口 类似'/article/:id'这样的动态接口 => '/article/123'
const replacePathVariables = (
  url: NitroFetchRequest,
  params: Record<string, any> = {}
) => {
  // 是否携带参数
  if (Object.keys(params).length === 0) {
    return url;
  }
  // 取出后面的变量 /: 开头的变量
  const regex = /\/:(\w+)/gm;
  let formattedURL = url as string;
  let match: RegExpExecArray | null;

  // 使用 while 循环匹配正则表达式
  while ((match = regex.exec(formattedURL)) !== null) {
    const paramName = match[1]; // 获取匹配的参数名
    if (params[paramName] === undefined) {
      throw new Error(`"${paramName}" is not provided in params`);
    }
    // 替换 URL 中的动态参数
    formattedURL = formattedURL.replace(`:${paramName}`, params[paramName]);
    delete params[paramName]; // 删除已使用的参数
  }

  return formattedURL;
};

export async function getFetchData({
  url,
  opts,
  method,
  contentType = "application/json",
  timeout = 5000, // 设置默认超时为 5000 毫秒
}: Params) {
  const config = useRuntimeConfig();
  // console.log(config);
  const requestURL = replacePathVariables(url, opts);

  // 保证页面刷新时可以拿到数据
  await nextTick();
  const data = await $fetch(requestURL, {
    method,
    lazy: true,
    timeout,
    // 请求拦截
    onRequest({ request, options }) {
      // request 当前的请求地址

      // 源码中需要创建一个对象，才能使用对应的属性
      options.headers = new Headers(options.headers);
      // 设置请求头
      options.headers.set("Content-Type", contentType);

      // 设置 token
      const token = useTokenCookie();
      if (options.headers && token.value) {
        options.headers.set("Authorization", token.value);
        // console.log(options.headers.get("Authorization"));
      }

      // 设置 baseURL
      options.baseURL = config.public.apiBase as string;

      // 设置请求参数
      if ({ ...opts }) {
        switch (method) {
          case "GET":
            options.query = opts;
            break;
          case "POST":
            options.body = opts;
            break;
          default:
            break;
        }
      }

      // loading
      // if (reqNum == 0) {
      // }
      // reqNum++;
      let reqUrl: string = request as string;
      if (
        reqUrl.includes("quickComputer") ||
        reqUrl.includes("priceComputer") ||
        reqUrl.includes("Pay") ||
        reqUrl.includes("client.server.Server/status?server_id") ||
        reqUrl.includes("ServerPrice/upgradeComputer")
      ) {
        // showLoading.value = false;
        reqNum = 0;
      } else if (reqNum === 0) {
        // showLoading.value = true;
        reqNum++;
      }
    },
    // 响应拦截
    onResponse({ request, response, options }) {
      reqNum--;
      // if (reqNum == 0) {
      // }

      // 处理响应
      options.headers = new Headers(options.headers);
      // 无感刷新
      if (options.headers.get("Authorization")) {
        // console.log(options.headers.get("Authorization"));
        const token = useTokenCookie();
        token.value = options.headers.get("Authorization") as string;
      }

      // console.log(response);

      // 统一处理 特殊的状态码
      if (
        response._data &&
        typeof response._data == "object" &&
        response._data.code === 50001 &&
        isShowMessage
      ) {
        // Token过期处理逻辑
        handleTokenExpired(50001, response._data);
      } else if (
        response._data &&
        typeof response._data == "object" &&
        response._data.code === 50002 &&
        isShowMessage
      ) {
        // 无权限操作
        handleTokenExpired(50002, response._data);
      } else {
        isShowMessage = true;
      }

      endLoading();
      // 是否要返回有待处理
      // return response;
    },
    // 请求错误
    onRequestError({ request, options, error }) {
      // 处理请求错误
      endLoading();
      console.log(error);
    },
    // 响应错误
    onResponseError({ request, response, options }) {
      // 处理响应错误
      endLoading();
      handleError(response, options);
    },
  });

  // 这里data本身是个ref对象，将其内部值抛出去方便调用时获得数据。
  return data;
}

// 处理 error
const handleError = (res: FetchResponse<any>, opts: FetchOptions) => {
  console.log(res, opts);
  let message;
  if (res) {
    message = "系统错误";
  } else {
    message = "请求出错";
  }
  ElMessage({
    type: "error",
    message,
  });
};

// 关闭loading
const endLoading = () => {
  if (reqNum <= 0) return;
  reqNum--;
  if (reqNum === 0) {
    //loading结束
    setTimeout(() => {}, 400);
  }
};

// 处理 token 失效
const handleTokenExpired = async (
  status: string | number,
  res: Record<string, any>
) => {
  // 确保只弹出一次
  if (alertFlag) {
    let message: string = "";
    if (status === 50001) {
      message = res.msg || "Token 过期，请重新登录";
    } else if (status === 50002) {
      message = res.msg || "无权限操作";
    }

    alertFlag = false; // 设置为 false，避免重复弹出

    try {
      await navigateTo("/login");
      // @ts-ignore
      await ElMessageBox.alert(message, "系统提示", {
        showConfirmButton: false,
        lockScroll: false,
        appendTo: document.querySelector(".login"),
      });
    } finally {
      // 重新设置为 true，允许下次弹出
      alertFlag = true;
      isShowMessage = false;
    }
  }

  isShowMessage = true; // 确保其他逻辑能够正常处理
};

interface FetchConfig {
  url: NitroFetchRequest;
  opts?: Record<string, any>;
  timeout?: number;
}

export const fetchData = {
  get: ({ url, opts, timeout }: FetchConfig) => {
    return getFetchData({ url, opts, method: "GET", timeout });
  },
  post: ({ url, opts, timeout }: FetchConfig) => {
    return getFetchData({ url, opts, method: "POST", timeout });
  },
};
