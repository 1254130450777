<template>
  <div class="loading">
    <div class="bg">
      <img src="@/assets/img/loding.gif" alt="">
    </div>
  </div>
</template>

<script setup>
</script>


<style lang="less" scoped>
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.9;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg {
    width: 200px;
    height: 200px;
    // background: url(@/assets/img/loading-bg.png) 0 0 / 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
}
</style>