import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// import { faYenSign } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

export default defineNuxtPlugin((nuxtApp) => {
  library.add(fas, far);
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon);
});
