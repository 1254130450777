import type languageType from "./type.js";
const en: languageType = {
  navbar: {
    fontPage: "Home",
    gameList: "Game List",
    helpCenter: "Help Center",
    newsCenter: "News Center",
    login: "Login",
    register: "Register",
    aboutUs: "About Us",
    searchGame: "Search Game",
    userCenter: "User Center",
  },
  footer: {
    companyName: "Particle Data (Shenzhen) Co., Ltd.",
    company: "Company",
    aboutUs: "About Us",
    contactUs: "Contact Us",
    joinUs: "Join Us",
    game: "Game",
    topGames: "Top Games",
    newGamesRecomand: "New Games Recommend",
    gameRanking: "Game Ranking",
    comunity: "Community",
    playerForum: "Player Forum",
    gameStrategy: "Game Strategy",
    playerShare: "Player Share",
    focusUs: "Focus Us",
    copyright: "©2024 Particle Data (Shenzhen) Co., Ltd. All rights reserved.",
    icp: "粤ICP备2022088627号-4",
    publicNet: "粤公网安备44030902003679号",
    privacy: "Privacy Policy",
    termsOfService: "Terms of Service",
    cookieSettings: "Cookie Settings",
    companyInfo:
      "Our products include an easy control panel and server tools for beginners. More gamer services are coming soon.",
    companyInfo1: "Community",
    companyInfo3: "Address",
    companyInfo4: "Thank you for your support and trust!",
    companyAddress:
      "No. 711, Building 1, West Village, Baoan District, Shenzhen, Guangdong Province, China",
  },
  container: {
    quantumPanel: "Quantum Panel",
    linkageDiscount: "Linkage Discount",
    summerSpecial: "Summer Special",
    agencyDiscount: "Agency Discount",
    quantumGamePanel: "Quantum Game Panel",
    panelDescrible:
      "We provide a variety of Web game server services to meet your gaming needs.",
    buttonViewGame: "View Game",
    buttonStartServer: "Start Server Now",
    minecraft: "Minecraft",
    minecraftDescrible:
      "Minecraft is a sandbox game where players can build, collect, and battle.",
    palworld: "Palworld",
    palworldDescrible:
      "Palworld is a 3D action-adventure game where players can fight, explore, and collect.",
    csgo: "CSGO2",
    csgoDescrible: "CSGO is a shooting game where players can shoot and fight.",
    riskInTheRain: "Risk in the Rain",
    riskInTheRainDescrible:
      "Risk in the Rain is a puzzle-adventure game where players can solve puzzles and explore.",
    imperialMyth: "Imperial Myth",
    imperialMythDescrible:
      "Imperial Myth is a strategy game where players can strategize and battle.",
    sevenDays: "Seven Days to Die",
    sevenDaysDescrible:
      "Seven Days to Die is a survival game where players can survive and fight.",
    mistlockKingdom: "Mistlock Kingdom",
    mistlockKingdomDescrible:
      "Mistlock Kingdom is a strategy game where players can strategize and battle.",
    forest: "Forest",
    forestDescrible:
      "Forest is a strategy game where players can engage in strategy, combat, and other operations.",
    terraria: "Terraria",
    terrariaDescrible:
      "Terraria is a sandbox game where players can build, gather resources, and engage in combat.",
    strave: "Don't Starve Together",
    straveDescrible:
      "Don't Starve Together is a survival game where players must complete various challenges and collect items.",
    craftopia: "Craftopia",
    craftopiaDescrible:
      "Craftopia is a sandbox game where players can build, collect resources, and engage in combat.",
  },
  banner: {
    title: "Diverse Game Selection",
    titleDescrible:
      "We offer a variety of server options for popular games to meet your gaming needs.",
    imgGameName1: "CSGO2",
    imgGameDescrible1:
      "CSGO is a shooting game where players can shoot and fight.",
    imgGameName2: "Minecraft",
    imgGameDescrible2:
      "Minecraft is a sandbox game where players can build, collect, and battle.",
  },
  scrollList: {
    imgGameName2: "Minecraft",
    imgGameDescrible2:
      "Minecraft is a sandbox game where players can build, collect, and battle.",
  },
  testimonial: {
    title: "Wonderful Gaming Experience",
    titleDescrible: "User reviews of our game servers",
    bottonViewMore: "Learn More",
    playerName1: "Daz",
    playerEvaluate1:
      "Very good game server, very stable, and fun player interaction. Highly recommended!",
    playerName2: "The Magic Old Man",
    playerEvaluate2:
      "The game server is very stable, the gaming experience is very good, and I recommend it to everyone!",
    playerName3: "Monkey on the Mountain",
    playerEvaluate3:
      "I have spent a lot of wonderful time on this game server, I really like it!",
  },
};

export default { ...en };
