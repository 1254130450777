import { defineStore } from "pinia";
import {
  getUserLogin,
  getUserLoginByCode,
  getUserLoginByPwd,
  getVerifyCode,
  getTokenValid,
} from "@/server/login/login";

const useLoginStore = defineStore(
  "login",
  () => {
    const phone = ref("");
    const phoneCode = ref("");
    const mobile = ref("");
    const code = ref("");
    const token = ref("");

    const getPhoneCode = async (phoneCodeVal, mobileVal) => {
      const res: any = await getVerifyCode(phoneCodeVal, mobileVal);
      phone.value = res.phone;
      return res;
    };

    const getUserLoginInfo = async (
      phoneCodeVal,
      mobileVal,
      codeVal,
      password,
      confirmPassword
    ) => {
      const res = await getUserLogin(
        phoneCodeVal,
        mobileVal,
        codeVal,
        password,
        confirmPassword
      );
      phoneCode.value = phoneCodeVal;
      mobile.value = mobileVal;
      code.value = codeVal;
      return res;
    };

    // 验证码登录
    const userLoginByCode = async (phoneCodeVal, mobileVal, codeVal) => {
      const res: any = await getUserLoginByCode(
        phoneCodeVal,
        mobileVal,
        codeVal
      );
      token.value = "Bearer " + res.data;

      // 存入 cookie
      const tokenCookie = useTokenCookie();
      tokenCookie.value = token.value;

      return res;
    };

    // 密码登录
    const userLoginByPwd = async (phoneCodeVal, mobileVal, password) => {
      const res: any = await getUserLoginByPwd(
        phoneCodeVal,
        mobileVal,
        password
      );
      token.value = "Bearer " + res.data;

      // 存入 cookie
      const tokenCookie = useTokenCookie();
      tokenCookie.value = token.value;

      return res;
    };

    // 验证token是否有效
    const getTokenValidInfo = async (tokenVal) => {
      const res = await getTokenValid(tokenVal);
      return res;
    };

    // 设置token
    const setToken = (tokenVal) => {
      token.value = tokenVal;
    };

    // 获取token
    const getToken = () => {
      return token.value;
    };

    // 清除 token
    const clearToken = () => {
      const tokenCookie = useTokenCookie();
      tokenCookie.value = "";
      token.value = "";
    };

    // 推出登录
    const logout = () => {
      const tokenCookie = useTokenCookie();
      tokenCookie.value = "";
      token.value = "";
      navigateTo("/");
    };

    return {
      phone,
      phoneCode,
      mobile,
      code,
      token,
      getPhoneCode,
      getUserLoginInfo,
      userLoginByCode,
      userLoginByPwd,
      getTokenValidInfo,
      setToken,
      logout,
      getToken,
      clearToken,
    };
  },
  {
    persist: {
      key: "lizigo_login",
      storage: persistedState.localStorage,
    },
  }
);

export default useLoginStore;
