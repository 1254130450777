import "@/utils/flexible";
import Vue3Marquee from "vue3-marquee";
import Terminal from "vue-web-terminal";
import { type Plugin } from "vue";
// 引入 elementPlus 分页为英文的问题
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

export default defineNuxtPlugin((nuxtApp) => {
  // 修改分页器默认文字
  zhCn.el.pagination.total = "共 {total} 条记录";
  zhCn.el.pagination.goto = "跳至";
  zhCn.el.pagination.pagesize = "条/页";
  zhCn.el.pagination.pageClassifier = "页";

  // 不支持链式调用
  nuxtApp.vueApp.use(ElementPlus, { locale: zhCn });
  nuxtApp.vueApp.use(Vue3Marquee);
  nuxtApp.vueApp.use(Terminal as unknown as Plugin);
});
